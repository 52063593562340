<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcChurchCommunications }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ translations.tcAddresses }}</h2>
          <b-button variant="primary" v-if="iCanSeeAny(secured_address_add_controls)" @click="handleAddAddressClick">{{
          translations.tcAddAddress
          }}</b-button>
        </div>
        <div v-if='translations.components' class="d-flex flex-wrap">
          <ServiceCard v-for="(address, index) in churchCommunicationsData.addresses" :i18n="translations.components"
            :key="index" :obj="address"
            :secure_delete_keys="{ ...secured_address_delete_controls, ...secured_address_add_controls }"
            :secure_edit_keys="{ ...secured_address_edit_controls, ...secured_address_add_controls }"
            @edit_requested="handleAddressEditRequest(address)"
            @delete_approved="handleDeleteRequest(address.adr_key, 2)"></ServiceCard>
        </div>
      </section>
      <section class="section-2">
        <div class="d-flex section-header">
          <h2>{{ translations.tcCommunications }}</h2>
          <b-button variant="primary" v-if="iCanSeeAny(secured_phone_fax_add_controls)" @click="handleAddPhoneFaxClick">
            {{ translations.tcAddPhoneOrFax }}</b-button>
          <b-button variant="primary" v-if="iCanSeeAny(secured_email_add_controls)" @click="handleAddEmailClick">{{
          translations.tcAddEmail
          }}</b-button>
        </div>
        <div v-if='translations.components' class="d-flex flex-wrap">
          <ServiceCard v-for="(phone, index) in churchCommunicationsData.phones" :i18n="translations.components"
            :key="index" :obj="phone"
            :secure_edit_keys="{ ...secured_phone_fax_edit_controls, ...secured_phone_fax_add_controls }"
            :secure_delete_keys="{ ...secured_phone_fax_delete_controls, ...secured_phone_fax_add_controls }"
            @edit_requested="handlePhoneFaxEditRequest(phone)" @delete_approved="handleDeleteRequest(phone.phn_key, 1)">
          </ServiceCard>
          <ServiceCard v-for="(email, index) in churchCommunicationsData.emails" :i18n="translations.components"
            :key="index + 50" :obj="email"
            :secure_edit_keys="{ ...secured_email_edit_controls, ...secured_email_add_controls }"
            :secure_delete_keys="{ ...secured_email_delete_controls, ...secured_email_add_controls }"
            @edit_requested="handleEmailEditRequest(email)" @delete_approved="handleDeleteRequest(email.eml_key, 0)">
          </ServiceCard>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-communications',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      secured_email_add_controls: {
        add_church_details_email_button: '35c76b82-4410-4f8c-a18e-fb11fd2f3248',
      },
      secured_email_edit_controls: {
        edit_church_details_email_button: '2f7a0e79-812c-4edf-9ee5-2019dae88159',
      },
      secured_email_delete_controls: {
        delete_church_details_email_button: 'e09223e1-9e6b-4896-ad09-e48970b3d2f1',
      },
      secured_phone_fax_add_controls: {
        add_church_details_phone_fax_button: '30cf5c7e-285b-4ebd-b8d8-f960cced16d7',
      },
      secured_phone_fax_edit_controls: {
        edit_church_details_phone_fax_button: '9927b6d1-ecf2-4d75-8307-2e9fa02b7d4a',
      },
      secured_phone_fax_delete_controls: {
        delete_church_details_phone_fax_button: '0adc5414-fa34-43b6-a742-fa77bdba4f01',
      },
      secured_address_add_controls: {
        add_church_details_address_button: '8ec37c2c-0592-43eb-8315-be765f3c86d7',
      },
      secured_address_edit_controls: {
        edit_church_details_address_button: '5f1dd006-209b-4770-a1c3-5ef74a9203c2',
      },
      secured_address_delete_controls: {
        delete_church_details_address_button: 'a8859a49-1cbb-4999-a99b-64e0b892c98e',
      },
      backJumpObj: {
        link: '#',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      deleteChurchEmailPhoneAddress: 'churchMinistry/deleteChurchEmailPhoneAddress',
      getChurchCommunications: 'churchMinistry/getChurchCommunications',
      loadChurchDetail: 'churchMinistry/loadChurchDetailSelect',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedEmail: 'user/setSelectedEmail',
      setSelectedPhone: 'user/setSelectedPhone',
    }),
    async handleAddAddressClick() {
      await this.setSelectedAddress(this.emptyAddress)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-address')
    },
    async handleAddEmailClick() {
      await this.setSelectedEmail(this.emptyEmail)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-email')
    },
    async handleAddPhoneFaxClick() {
      await this.setSelectedPhone(this.emptyPhoneFax)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-phone-fax')
    },
    async handleAddressEditRequest(address) {
      await this.setSelectedAddress(address)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-address')
    },
    async handleEmailEditRequest(email) {
      await this.setSelectedEmail(email)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-email')
    },
    async handlePhoneFaxEditRequest(phone) {
      await this.setSelectedPhone(phone)
      this.$router.push('/programs/cm/churchProfile/add-edit-church-phone-fax')
    },
    async handleDeleteRequest(key, sw) {
      let result = true
      this.setLoadingStatus(true)
      await Promise.all([
        (result = await this.deleteChurchEmailPhoneAddress({
          key: key,
          sw: sw,
        })),
        await this.pageLoad(),
      ]).then(() => {
        this.$swal({
          icon: result ? 'success' : 'error',
          showCancelButton: false,
          text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
          confirmButtonText: this.translations.tcOk
        })
      })
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadChurchDetail(),
        await this.getChurchCommunications(this.userSelectedChurchKey),
      ]).then(() => {
        this.backJumpObj.location = this.churchDetail.church_name
        this.backJumpObj.link = '/programs/cm/church-profile'
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      churchCommunicationsData: 'churchMinistry/churchCommunicationsData',
      churchDetail: 'churchMinistry/churchDetailSelect',
      emptyAddress: 'churchMinistry/empty_address',
      emptyEmail: 'churchMinistry/empty_email',
      emptyPhoneFax: 'churchMinistry/empty_phone_fax',
      iCanSeeAny: 'user/iCanSeeAny',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('back-jump', 'quick-jump', 'service-card'),
        this.getComponentTranslations(
          'common.address-types', 'common.email-types', 'common.fax-types', 'common.phone-types'
        ),
        this.pageLoad(),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error('Error during the pageLoad(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
